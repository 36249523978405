//import React from 'react';
import {useRef, useState, useEffect} from 'react';
export var currentUrl=window.location.origin
export var titlePage=""

var ua = navigator.userAgent.toLowerCase()
export const isMobile=(/iphone/.test(ua)) || (/android/.test(ua)) // || (/ipad/.test(ua))
// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function(search, replace) 
{
return this.split(search).join(replace);
}
//ie 8 does`t support indexOf, fix it
if (!Array.prototype.indexOf) { // eslint-disable-next-line no-extend-native
    Array.prototype.indexOf = function(obj, start) {
         for (var i = (start || 0), j = this.length; i < j; i++) {
             if (this[i] === obj) { return i; }
         }
         return -1;
    }
}

export const encodeDataToURL=(data)=> 
{
return Object
.keys(data)
.map(value => `${value}=${encodeURIComponent(data[value])}`)
.join('&');
 }

//animation part
export const useElementOnScreen = (options,notOnce=false)  => { 
const containerRef = useRef(null)
const [isVisible,setlsVisible] = useState(false)
const callbackFunction = (entries)  =>  {
const [entry] = entries
setlsVisible((isVisible===false || notOnce===true)?entry.isIntersecting:true) //only once if not set option
}
useEffect(() => {
const observer = new IntersectionObserver(callbackFunction, options)
if (containerRef.current) observer.observe(containerRef.current)
return () => {// eslint-disable-next-line
if(containerRef.current) observer.unobserve(containerRef.current)
}// eslint-disable-next-line
}, [containerRef, options])  
return [containerRef,isVisible]
}

export function Animation(props) //animate when go to viewport
{
const [containerRef,isVisible] = useElementOnScreen({root: null, rootMargin: "0px", threshold:0.5})
return <div ref={containerRef} className={(isVisible)?props.className:""}>
{props.children}
</div>
}
//end animation part

export function getQueryParams(qs) //get parametrs from url
{
const re = /[?&]?([^=]+)=([^&]*)/g
    qs = qs.split('+').join(' ')
    var params = {},tokens
    while ( (tokens = re.exec(qs)) )  params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2])
    return params;
}