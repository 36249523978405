import './App.scss';
import Main from './main';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


function App() {
  return (
    <div className="App">
    <Router basename={window.location.pathname}>
<Routes>
<Route exact path="/" element={<Main />} />
</Routes>
    </Router>
    </div>
  );
}

export default App;