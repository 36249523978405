import './main.scss';
import {useState,useRef,useEffect} from 'react';
import { useForm} from 'react-hook-form';
import axios from 'axios';
import {Animation,useElementOnScreen,encodeDataToURL, getQueryParams} from "./functions";

function FormRequest() /*block of form*/
{
const [loading, setLoading]=useState(false)
const [thankyou, setThankyou]=useState(false)
const { register, handleSubmit,  formState: { errors },reset} = useForm();
const onSubmit=data=>sendMe(data)



const sendMe=(data)=>
{
if (loading) return
setLoading(true)
setThankyou(false)
const query=getQueryParams(document.location.search)
  if (typeof query.rv!=='undefined') data={...data,"cs_random-variable":query.rv}
axios({
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    url: 'https://miomirror.co/form.php',
	data:  encodeDataToURL(data)
})
.then(response=> { 
  if (response.data!=='failed'/* && response.data!==''*/)
   {
setLoading(false)
setThankyou(true)
reset()
window.setTimeout(function(){setThankyou(false)},10000)
//alert('Thank you')
   }
  else
   {
alert('Something bad. Information:'+response.data)
setLoading(false)
   }
//console.log(response);
})
.catch(function (error) {
alert('Something bad. Information: '+error)
setLoading(false)
//console.log(error);
});
}

return(
<div className="block_form">
		<div className="wrapper">
<div className="title">SELL MORE WITH M!O | AN ENGAGING INTERACTIVE MIRROR FOR RETAIL</div>
    <form onSubmit={handleSubmit(onSubmit)}>
<div className="input">
<input type="text" name="fname" placeholder="FIRST NAME" {...register('fname', { required: false, maxLength: 255 })}  onClick={(e)=>{e.target.select()}} />
<div className={(errors.name)?"input_error":"hided_error"}>This field is required</div>
</div>
<div className="input">
<input type="text" name="name" placeholder="LAST NAME" {...register('lname', { required: false, maxLength: 255 })}  onClick={(e)=>{e.target.select()}} />
<div className={(errors.lname)?"input_error":"hided_error"}>This field is required</div>
</div>
<div className="input">
<input type="text" placeholder="EMAIL" name="email" {...register('email', { required: true, pattern: /^\S+@\S+$/i })} onClick={(e)=>{e.target.select()}} />
<div className={(errors.email)?"input_error":"hided_error"}>Enter valid email</div>
</div>
<div>
<div className="button" onClick={handleSubmit(onSubmit)}>REQUEST A DEMO TODAY</div>
<div className="hided_error"></div>
</div>
	</form>
<div className={(thankyou)?"thankyou":"hided"}>THANK YOU FOR YOUR INTEREST IN MIO!<br />WE WILL BE IN TOUCH SHORTLY</div>

		</div>
</div>
)
}

function VideoTracked(props) //video with tracker
{
const video=useRef(null)
const [videoPlay,setVideoPlay]=useState(false)
const [currentProgress,setCurrentProgress]=useState(0)

//tracker part
const rv=(typeof getQueryParams(document.location.search).rv==='undefined')?false:getQueryParams(document.location.search).rv
//const rv='test'
let allTime=0, lastTime=0, stop=false, timer=null
const progressRef = useRef()
progressRef.current = currentProgress
//const [mousePos,setMousePos]={x:null,y:null}
const trackStart=()=>
{
lastTime=Date.now()
stop=false
trackTimer()
//console.log('start')
}
const trackPause=()=>
{
stop=true
//console.log('pause')
}
const trackTimer = () =>
{
//console.log('timerFunc ',stop);
  if (stop===true) return false
const now = Date.now();
const passed = now - lastTime;
lastTime=now
allTime+=passed

//if (this.allTime%5==0){
sendMe()
//}
/* 
const format = (n) => (n + '').length >= 2 ? n : '0' + n;
const mins = Math.floor(this.allTime / 60000);
const secs = Math.floor(this.allTime % 60000 / 1000);*/
window.clearTimeout(timer)
timer=setTimeout(()=>trackTimer(), 10000);
}
const sendMe=()=> //send track data
{
//console.log(progressRef.current,Math.round(progressRef.current))
const data={time:allTime,"cs_random-variable":rv,video:Math.round(progressRef.current)}
axios({
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    url: 'https://miomirror.co/track.php',
	data:  encodeDataToURL(data)
})
.then(response=> { 
//alert('Thank you')
})
.catch(function (error) {
console.log(error);
});
}

useEffect(() => { //add events
 if (rv!==false) //enable only if in url we have parametr for it
  {
window.addEventListener("blur", trackPause);
window.addEventListener("focus", trackStart);
trackStart()
  }
//window.addEventListener("mousemove", (e)=>{setMousePos({x:e.pageX,y:e.pageY})});
    return () => {
window.removeEventListener("blur", trackPause);
window.removeEventListener("focus", trackStart);
//window.removeEventListener("mousemove", trackStart);
    };
}, []);
//end tracker part

const playVideo=()=>
{
returnProgress();
  if (videoPlay)
   {
setVideoPlay(false)
video.current.pause()
   }
  else 
   {
setVideoPlay(true)
video.current.play()
   }
}
const scrollBottom=()=>
{
props.scrollBottom()
video.current.pause()
setVideoPlay(false)
}

const returnProgress=()=>
{
  if (video.current!==null) setCurrentProgress((video.current.currentTime/video.current.duration)*100)
}

return(<div>
<div className="video_progress" style={{width:currentProgress+'%'}}></div>
<div className="video">
<div className="play" style={{backgroundImage:(videoPlay)?"none":`url(${'i/play.svg'})`}} onClick={()=>playVideo()}>
<img src="./i/arrow_bottom.svg" className="arrow_bottom" alt="" onClick={(e)=>{e.stopPropagation();scrollBottom()}} style={{display:(props.isVisible)?"block":"none"}} />
</div>
<video src="./video.mp4" poster="./video.jpg" id="main_video" ref={video} onEnded={()=>setVideoPlay(false)} onTimeUpdate={()=>returnProgress()} />
</div>
</div>)

}

function Main() {
const scrollto=useRef(null)
const [mainBlock,isVisible] = useElementOnScreen({root: null, rootMargin: "100px", threshold:1.0},true)

const scrollBottom=()=>
{
window.scrollTo({top: scrollto.current.offsetTop,behavior: "smooth"})
}


  return (
    <div>
<div className="logo" ref={mainBlock}><img alt="" src="./i/logo.svg" /></div>
<VideoTracked isVisible={isVisible} scrollBottom={scrollBottom} />
<div ref={scrollto}>
<Animation className="anim">
<FormRequest  />
</Animation>
</div>
<Animation className="anim">
<div className="block2">
<div className="image"><img alt="" src="./i/top.jpg" onLoad={(e)=>e.target.style="opacity:1"} /></div>
	<div className="column_right">
<div className="title">M!O, M!O, ON THE WALL</div>
<div className="subtitle">Who’s the best point-of-sale solution of all?</div>
<div className="text">Introducing M!O, the revolutionary new customer engagement experience that treats your customer like royalty. This is shopping in the style the future always promised.</div>
{/*<br /><br />*/}
	</div>
</div>
</Animation>

<Animation className="anim">
<div className="block3">Customers are 7x more likely to purchase if they have a good fitting room experience.</div>
</Animation>

<Animation className="anim">
<section className="section section_reverse section_first">
	<div className="second_column"><img onLoad={(e)=>e.target.style="opacity:1"} alt="" src="./i/1.jpg" /></div>
	<div className="first_column">
<div className="line"></div>
<div className="title">M!O FITTING ROOM MIRROR</div>
<div className="subtitle">Personalized Fashion</div>
		<ul className="list">
<li className="el">View product information</li>
<li className="el">Request size and colour options right from the fitting room</li>
<li className="el">Show recommendations and inspirations to complete the look</li>
<li className="el">Link Loyalty rewards during the consideration phase</li>
<li className="el">Purchase from the fitting room, pick up at the counter</li>
		</ul>
	</div>
</section>
</Animation>

<Animation className="anim">
<section className="section">
	<div className="second_column"><img onLoad={(e)=>e.target.style="opacity:1"} alt="" src="./i/2.jpg" /></div>
	<div className="first_column">
<div className="line"></div>
<div className="title">M!O SALES FLOOR MIRROR</div>
<div className="subtitle">Fashion’s Runway in the Aisles</div>
		<ul className="list">
<li className="el">Browse collections</li>
<li className="el">View product breakdowns</li>
<li className="el">Easy find and search for shoppers</li>
<li className="el">Order items to fitting room</li>
<li className="el">Purchase directly from the mirror</li>
		</ul>
	</div>
</section>
</Animation>

<Animation className="anim">
<section className="section section_reverse">
	<div className="second_column"><img onLoad={(e)=>e.target.style="opacity:1"} alt="" src="./i/3.png" className="image_phone" /></div>
	<div className="first_column">
<div className="line"></div>
<div className="title">EMPLOYEE NOTIFICATIONS</div>
<div className="subtitle">The tool to delivering a next level shopping&nbsp;experience</div>
		<ul className="list">
<li className="el">View and manage requests from the fitting rooms</li>
<li className="el">Understand client preferences for upsell and cross&nbsp;sell&nbsp;opportunities</li>
<li className="el">Access to clients’ previous purchase preferences</li>
		</ul>
	</div>
</section>
</Animation>

<Animation className="anim">
<section className="section">
	<div className="second_column"><img onLoad={(e)=>e.target.style="opacity:1"} alt="" src="./i/4.png" className="image_note" /></div>
	<div className="first_column">
<div className="line"></div>
<div className="title">DATA COLLECTION AND ANALYSIS</div>
<div className="subtitle">Your Complete Dashboard of Sales Intelligence Services</div>
<div className="text">Facilitate decision making based on actionable insights with information and data not previously available until Mio! could reflect customers’ personal tastes, styles and size preferences.</div>
		<ul className="list">
<li className="el">Better understand your customers</li>
<li className="el">Collect user data</li>
<li className="el">View customer feedback</li>
<li className="el">Accuractely analyze product performance</li>
<li className="el">Discover most popular items, sizes, colours, price points</li>
<li className="el">Optimize employee resources</li>
<li className="el">View key performance data</li>
		</ul>
<a className="button" href="https://commersive.ca/data-collection" target="_blank" rel="noreferrer">Learn more</a>
	</div>
</section>
</Animation>
<div className="bottom_wrapper"></div>

<Animation className="anim">
<FormRequest />
</Animation>

<Animation className="anim">
<div className="footer">
<div className="title">INTEGRATIONS</div>
<div className="subtitle">Perfect fit for any platform</div>
<div className="subsubtitle">Our systems work seamlessly with a variety of platforms to bring all your data into your CMS.</div>
	<div className="logos">
<img alt="" src="./i/logo1.png" onLoad={(e)=>e.target.style="opacity:1"} />
<img alt="" src="./i/logo2.png" onLoad={(e)=>e.target.style="opacity:1"} />
<img alt="" src="./i/logo3.png" onLoad={(e)=>e.target.style="opacity:1"}/>
	</div>
</div>
</Animation>

    </div>
  );
}

export default Main;